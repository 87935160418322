import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши курсы в EchoStrings Academy
			</title>
			<meta name={"description"} content={"Откройте для себя мир музыки – игра на гитаре и вокал доступны каждому"} />
			<meta property={"og:title"} content={"Наши курсы в EchoStrings Academy"} />
			<meta property={"og:description"} content={"Ознакомьтесь с широким спектром онлайн-курсов"} />
			<meta property={"og:image"} content={"https://klixom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://klixom.com/img/10250-2275.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://klixom.com/img/10250-2275.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://klixom.com/img/10250-2275.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://klixom.com/img/10250-2275.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://klixom.com/img/10250-2275.png"} />
			<meta name={"msapplication-TileImage"} content={"https://klixom.com/img/10250-2275.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 120px 0" background="linear-gradient(180deg,rgba(4, 3, 30, 0.9) 1%,rgba(5, 15, 49, 0.89) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://klixom.com/img/4.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Headline-2">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text margin="0px 0px 20px 0px" font="normal 700 52px/1.2 --fontFamily-sans" color="--light" sm-font="normal 700 42px/1.2 --fontFamily-sans">
			Наши курсы
			</Text>
			<Text
				margin="0px 180px 80px 0px"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#aab1b9"
				lg-margin="0px 0 50px 0px"
				md-margin="0px 0 50px 0px"
			>
				В EchoStrings Academy мы предлагаем широкий спектр онлайн-курсов по игре на гитаре и вокалу, разработанных для студентов всех уровней подготовки. Наши курсы позволяют каждому найти что-то новое и интересное, углубить свои знания и умения, и даже начать свой путь в мире музыки.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="25px 25px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
						Основы игры на гитаре
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
						Этот курс предназначен для начинающих и покрывает все необходимые основы: от знакомства с инструментом до освоения первых аккордов и мелодий. Вы узнаете о различных техниках игры, чтении музыкальных нот и табулатур, что позволит вам быстро начать играть простые композиции.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
						Вокальные техники
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
						На этом курсе вы изучите основы правильного дыхания, развитие голосового диапазона, а также техники вокального исполнения. Курс подойдет как новичкам, так и тем, кто хочет улучшить свои вокальные навыки и научиться петь с уверенностью.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
						Продвинутая игра на гитаре
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
						Для тех, кто хочет углубить свои знания и умения в игре на гитаре, мы предлагаем курс, посвященный продвинутым техникам и стилям игры. Изучение сложных аккордов, фингерстайл, импровизация и создание собственных аранжировок – всё это ждет вас на этом курсе.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
						Композиция и аранжировка
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
						Этот курс предназначен для тех, кто хочет научиться создавать свою музыку. Вы познакомитесь с основами композиции, аранжировки и процесса записи. Курс поможет вам открыть в себе талант композитора и научит использовать современные музыкальные программы для записи своих треков.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
						Мастер-классы и воркшопы
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
						Регулярные мастер-классы и воркшопы от приглашенных звезд музыкальной индустрии и опытных преподавателей. Получите уникальный опыт и новые знания от мастеров своего дела.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					background="rgba(71, 70, 124, 0.25)"
					padding="25px 20px 25px 20px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--light" font="normal 500 22px/1.2 --fontFamily-sans">
						Индивидуальные уроки
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#aab1b9">
						Мы понимаем, что каждый студент уникален, поэтому предлагаем индивидуальные уроки с преподавателями. Это позволит вам работать над конкретными аспектами игры на гитаре или вокале, получить персонализированные рекомендации и быстрее достигнуть своих целей.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});